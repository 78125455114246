// libs
@import '../node_modules/bootstrap/scss/bootstrap';
@import '~@ng-select/ng-select/themes/material.theme.css';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '../node_modules/angular-calendar/css/angular-calendar.css';

@import 'assets/scss/bootstrap/bootstrap';

// project
@import 'assets/scss/_variables';
@import 'assets/scss/_constants';

@import 'mixins/margins';
@import 'mixins/paddings';

@import 'assets/scss/app';

@import 'assets/scss/reset';

@import 'assets/scss/typo';
@import 'assets/scss/modals';
@import 'assets/scss/forms';
@import 'assets/scss/form-fields';
@import 'assets/scss/form-select';
@import 'assets/scss/tables';
@import 'assets/scss/tree';
@import 'assets/scss/dc';
@import 'assets/scss/ui-slider';
@import 'assets/scss/buttons';
@import 'assets/scss/xbox';
@import 'assets/scss/tabs';
@import 'assets/scss/checkbox';
@import 'assets/scss/dc-slider';
@import 'assets/scss/dropdown-menu';
@import 'assets/scss/radio';
@import 'assets/scss/text';

/* Торги */
@import 'assets/scss/trades/trades';
@import 'assets/scss/trades/trade-card';
@import 'assets/scss/trades/lots';
@import 'assets/scss/trades/trade-report';
@import 'assets/scss/trades/notes';
@import 'assets/scss/trades/trades-print';

@import 'assets/scss/file-manager/file-manager';
@import 'assets/scss/chat/index';

/* Тарифы */
@import 'assets/scss/tariffs/tariffs';
@import 'assets/scss/tariffs/tariffs-lists';
@import 'assets/scss/tariffs/tariff-table';
@import 'assets/scss/tariffs/tariff-modal';
@import 'assets/scss/tariffs/payment-registry-table';
@import 'assets/scss/tariffs/manual-mode';

/* Сотрудники */
@import 'assets/scss/employees/employees';
@import 'assets/scss/employees/employees-print';

@import 'assets/scss/calendar';
@import 'assets/scss/employees/schedule';
@import 'assets/scss/tso/index';
@import 'assets/scss/month-picker/index';

@import 'assets/scss/profile/profile';

@import 'assets/scss/competence-map';

.mat-autocomplete-panel {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  &:not([class*='mat-elevation-z']) {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
}
