.change-tso-modal {
  .modal-dialog {
    max-width: 700px;
  }
}

.group-title-text {
  display: block;
  width: 201px;
  margin-right: 20px;

  > span {
    &:focus {
      outline: none;
    }
  }
}

.chat-number {
  background: rgba($color-brand-light, 50%);
  font-size: 9px;
  font-weight: 900;
  border-radius: 50%;
  min-width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.chat-number__10 {
  width: 20px;
  border-radius: 7px;
}

.chat_active::after {
  content: '';
  height: 30px;
  width: var(--after-width);
  background: rgba($color-brand-light, 30%);
  position: absolute;
  left: 0;
  z-index: -1;
  margin-left: -30px;
}
