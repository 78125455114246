// Оформление элементов форм
@import "parts/color"

label.error
  position: absolute
  margin-top: 40px
  font-size: 11px !important

// old radio

.radio
  cursor: pointer

  input[type="radio"]
    position: absolute
    opacity: 0

    + .radio-label
      &:before
        content: ''
        background: $white
        border-radius: 100%
        border: 1px solid $color-brand-light
        display: inline-block
        width: 20px
        height: 20px
        position: relative
        margin-right: 5px
        vertical-align: top
        cursor: pointer
        text-align: center
        transition: all 250ms ease

    &:checked
      + .radio-label
        &:before
          background-color: $color-brand-light
          box-shadow: inset 0 0 0 3px $white

    &:focus
      + .radio-label
        &:before
          outline: none
          border-color: $color-brand-light

    &:disabled
      + .radio-label
        &:before
          border-color: darken($white, 25%)
          background: transparent

    + .radio-label
      &:empty
        &:before
          margin-right: 0

    &:checked:disabled
      + .radio-label
        &:before
          background: darken($white, 25%)




#cbx:checked ~ .cbx,
#cbx2:checked ~ .cbx2,
#cbx-1:checked ~ .cbx,
#cbx-2:checked ~ .cbx,
#cbx-3:checked ~ .cbx,
#cbx-4:checked ~ .cbx,
.cbx-input:checked ~ .cbx-label
  &:before
    border-color: transparent
    background: $color-brand-light

  &:after
    opacity: 1
    transform: rotate(45deg) scale(1)

.cbx-input:checked:disabled ~ .cbx-label
  cursor: default

  &:before
    background: $color-brand-light


.cbx-input:disabled ~ .cbx-label
  cursor: default

// errors

ng2-datepicker.ng-touched.ng-invalid .datepicker-input
  background: #fde1e1

input.datepicker-input.ng-untouched.ng-invalid
  background: #fde1e1

input.ng-touched.ng-invalid
  background: #fde1e1

select.ng-touched.ng-invalid
  border-color: $color-red-orange

.form-control-feedback
  font-size: 0.8rem
  color: $color-red-orange
  padding: 0 5px
