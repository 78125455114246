$white: #fff;
$black: #000;
$green: #b9db92;
$red: #f88e86;
$yellow: #f2ec6f;
$blue-calendar: #e9f0f1;
$red: #ff0000;

/* Цвета интерфейса */
$gray-light: #c1c1c1; // Светло-серый цвет в интерфейсе & Отменено (календарь)
$gray: #e5e5e5; // Цвет для выделения выбранного тарифа в разделе Тарифы
$aqua-gray: #d4e1e4; // Цвет шапки ячеек таблицы
$aqua-gray-light: #e1eaed; // Цвет второй строки шапки ячеек таблицы реестр
$gray-light-table: #dee2e3; // Цвет фона руководителя
$gray-light-input: #f1f1f1; // Цвет disabled input (дизайнер пока не уточнил название)

$color-brand-light: #6d99a2; // Светлый фирменный цвет (1)
$color-brand-light-03: #d3e0e3; // Светлый фирменный цвет (1) псевдо-прозрачность 0.3
$color-brand-middle: #496f7a; // Средний фирменный цвет (2)
$color-brand-dark: #1c4b59; // Темный фирменный цвет (3)

$color-dark-charcoal: #323232; // Цвет текста & Держать на контроле (календарь)

$color-sky-blue: #87b6c0;
$blue-light: #f1f5f6; // Цвет фона владельца аккаунта

$color-snow: #f9f9f9; // Фон уведомлений в окне уведомлений
$color-white-smoke: #efefef; // Фон непрочитанных уведомлений в окне уведомлений

$color-mantis: #8bc34a; // Зеленый цвет уведомлений и статуса
$color-lemon: #e9e00f; // Желтый цвет уведомлений и статуса
$color-red-orange: #f44336; // Красный цвет уведомлений и статуса
$color-red-orange-03: rgba(244, 67, 54, 0.3); // Красный цвет c прозрачностью
$color-red-orange-05: #f5534780; // Красный цвет c прозрачностью

/* Цвета событий календаря */
$color-rose-bud: #fe9f98; // Важно в срок (календарь)
$color-tacao: #efea76; // Важно, но срок возможно перенести (календарь)
$color-summer-sky: #81c9fd; // Низкая важность, выполнить по возможности (календарь)
$color-calendar-success: #b3de82; // Выполнено (календарь)

/* Цвета файлообменника */
$color-benjamin: #aec4c9; // фон для количества выделенных фильтров в модалке share/unshare

/* OLD */
$brand: #6d99a2;
$brand-warn: #dad20e;
$brand-secondary: #496f7a;

/* Переопределение переменных bootstrap */
$mark-bg: rgba(109, 153, 162, 0.3);
$mark-padding: 0.2em 0.1em 0.2em 0.1em;

.color-brand-light {
  color: $color-brand-light;
}

.color-brand-middle {
  color: $color-brand-middle;
}

.bg-brand-light {
  background: $color-brand-light;
}

/* Уровни пользователей */
$level1: #d5d7d8;
$level2: #e3ecee;
$level3: #f0f5f6;
$level4: #d4e1e4;

$scroll: #737378; // Цвет скролла

/* Цвета таблицы */
$info-cell: #e3e3e3;
$table-header: #d4e1e4;
$table-extra-header: #e1eaed;
$red-light: #f4433626;
