@import 'variables';

$row-colors: (
  0: #cdcdcd,
  1: #dadada,
  2: #e5e5e5,
  3: #f1f1f1,
  4: #ffffff,
);

@mixin table-cell {
  border-bottom: 1px solid $gray-light;
  border-right: 1px solid $gray-light;
}

@mixin header-cell {
  display: flex;
  height: 50px;
  font-weight: 500;
}

.payment-registry-table {
  font-size: 14px;

  .col-sticky {
    position: sticky;
    z-index: 2;
    background-color: inherit;
  }

  .icon-wrapper {
    display: block;
  }

  .payment-registry-header {
    position: sticky;
    z-index: 3;
    top: 0;
  }

  .btn-wrapper {
    justify-content: space-between;
  }

  .table-header-button {
    width: 40px;
  }

  .header {
    @include header-cell;
    background-color: $aqua-gray;
    text-align: left;
    line-height: 16.41px;

    td {
      @include table-cell;
      padding: 8px 12px 0 12px;
    }
  }

  .header-extra {
    @include header-cell;
    background-color: $aqua-gray-light;

    td {
      @include table-cell;
      padding: 18px 12px 0 12px;
      font-weight: normal;

      &:not(:first-child) {
        text-align: end;
      }
    }

    .name {
      padding-top: 12px;
      font-weight: 500;
    }
  }

  tbody {
    @for $i from 0 through 4 {
      .tree-depth-#{$i} {
        $color: map-get($row-colors, $i);
        background-color: $color;
      }
    }

    .customer-row {
      background-color: $gray-light-input;
    }

    .transactions-row {
      background-color: $white;
    }

    tr {
      line-height: 10px;
      td {
        @include table-cell;
        padding: 6px 12px 0 12px;
        height: 50px;
        text-align: end;
        position: relative;
        vertical-align: middle;
        line-height: 16.71px;
      }

      .all-size {
        padding: 0;
      }

      .align-start {
        text-align: start;
      }

      .align-end {
        text-align: end;
      }

      .align-center {
        text-align: center;
      }
    }
  }
}
