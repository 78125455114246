@import 'variables';

.form-wrapper {
  .select-value {
    .ng-has-value .ng-placeholder {
      display: none;
    }
  }

  .ng-select.ng-select-single .ng-select-container {
    .ng-value-container .ng-input {
      top: 18px;
    }

    .ng-clear-wrapper {
      bottom: 16px;
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background-color: $aqua-gray;
    color: $black;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: $aqua-gray;
  }

  .ng-select {
    display: flex;
    line-height: normal;

    .ng-arrow-wrapper .ng-arrow {
      border-style: none !important;
      border-width: 0 !important;
    }

    .ng-spinner-loader {
      position: absolute;
      top: 20px;
      left: 45%;
    }
  }

  .ng-select-container {
    &:after {
      content: none;
    }
  }

  .ng-dropdown-panel {
    top: 50px;
    bottom: auto;
    width: 170px;

    .ng-dropdown-panel-items .ng-option {
      padding: 0 14px;
    }
  }

  .id-inn-cell {
    .ng-dropdown-panel {
      left: -9px;
    }
  }

  .user-cell {
    .ng-dropdown-panel {
      left: -170px;
    }
  }

  .status-cell {
    .ng-dropdown-panel {
      left: -346px;
    }
  }

  .full-data {
    .ng-dropdown-panel {
      width: 480px;
    }
  }
}

.table-manual-mode {
  position: relative;
  max-width: 100%;
  width: fit-content;
  max-height: 220px;
  overflow-y: auto;
  color: $color-dark-charcoal;
  border: 1px solid $gray-light;
  border-radius: 4px;

  .manual-mode {
    th {
      padding: 0;
      line-height: 16px;
    }

    .grid-wrapper:not(:last-child) {
      padding: 0;
    }

    &-table-header {
      height: 50px;
      box-shadow: 0 1px 0 0 $gray-light;
      cursor: grab;
      position: sticky;
      top: 0;
      z-index: 300;

      .grid-wrapper:not(:last-child) {
        border-right: 1px solid $gray-light;
      }

      .col {
        min-height: 51px;
        padding: 12px 12px 0 12px;
        border: none;
        font-weight: 500;
        background-color: $aqua-gray;
        box-shadow: 1px 0 0 0 $gray-light;
        display: flex;
        justify-content: space-between;
        height: 50px;

        &:last-child {
          border-right: none;
        }
      }
    }

    .table-row {
      display: flex;

      .cell {
        height: 50px;
        padding: 13px 12px 0 12px;
        border: none;
        border-right: 1px solid $gray-light;

        &:last-child {
          border-right: none;
        }
      }

      .id-inn-cell {
        padding-top: 9px;
      }
    }

    .table-row:not(:last-child) {
      border-bottom: 1px solid $gray-light;
    }
  }
}

.id-inn-cell .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  white-space: pre-wrap;
  word-break: break-word;
  max-width: 60%;
  position: absolute;
  top: 8px;
}
